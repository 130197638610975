<template>
  <f7-popup class="mediaupload-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_UPLOAD_MEDIA") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <template v-if="mediaSources?.length > 0">
        <f7-swiper navigation :slides-per-view="1" :space-between="20" :simulate-touch="false">
          <f7-swiper-slide v-for="(media, index) in mediaSources" :key="'ms_' + index" class="upload-media-container">
            <cropper v-if="open && media.type === 'IMAGE'" ref="cropper" class="cropper" :canvas="canvas" :src="media.source" :stencil-size="stencilSize" @change="cropperChange($event, media)"></cropper>

            <video v-if="open && media.type === 'VIDEO'" controls autoplay muted>
              <source :src="media.source" type="video/mp4" />
              {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
            </video>

            <div class="button-container">
              <div>
                <template v-if="open && media.type === 'IMAGE'">
                  <f7-button outline large @click="rotateImage($refs)">
                    <font-awesome-icon :icon="['far', 'sync']" fixed-width />
                  </f7-button>
                  <f7-button outline large @click="flipImage($refs)">
                    <font-awesome-icon :icon="['far', 'exchange-alt']" fixed-width />
                  </f7-button>
                </template>

                <f7-button fill large color="red" @click="removeMedia($refs, index)">
                  {{ $t.getTranslation("LBL_REMOVE_MEDIA") }}
                </f7-button>
              </div>
            </div>
          </f7-swiper-slide>
        </f7-swiper>
      </template>

      <template v-else>
        <f7-block class="select-media-container">
          <!-- <img src="@/assets/images/svg/upload-add.svg" alt="Select Image" /> -->
          <h1>{{ title || $t.getTranslation("LBL_BROWSE_SELECT_MEDIA") || "Browse/Select Media" }}</h1>

          <f7-button fill large @click="selectMedia($refs, 'IMAGE')"> {{ $t.getTranslation("LBL_SELECT_IMAGE") }} </f7-button>

          <f7-button fill large @click="selectMedia($refs, 'VIDEO')"> {{ $t.getTranslation("LBL_SELECT_VIDEO") }} </f7-button>

          <p>
            {{ description || $t.getTranslation("LBL_BROWSE_SELECT_MEDIA_SUB") || "Please select the image or video you want to use." }}<br />
            <strong>
              {{ $t.getTranslation("LBL_MAXIMUM_UPLOAD_SIZE_IS") || "Maximum upload size is" }}
              {{ imageSizeLimit }}MB {{ $t.getTranslation("LBL_FOR_IMAGES") || "for images" }} & {{ videoSizeLimit }}MB {{ $t.getTranslation("LBL_FOR_VIDEOS") || "for videos" }} .
            </strong>
          </p>
        </f7-block>
      </template>

      <div v-if="mediaSources?.length > 0" class="button-fixed-container">
        <!-- <f7-button fill large color="lightblue" @click="changeMedia($refs)">
          {{ $t.getTranslation("LBL_CHANGE_MEDIA") }}
        </f7-button> -->
        <f7-button fill large @click="useMedia()"> {{ $t.getTranslation("LBL_USE_MEDIA") }} </f7-button>
      </div>

      <input ref="fileInputImage" multiple type="file" accept="image/*" @change="loadMedia($event)" />
      <input ref="fileInputVideo" multiple type="file" accept="video/*" @change="loadMedia($event)" />
      <!-- accept="image/*,video/*" -->
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { f7 } from "framework7-vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { useStore } from "@/store";

export default defineComponent({
  name: "MediaUploadPopUpComponent",
  components: { Cropper },
  props: {
    open: Boolean,
    title: String,
    description: String,
    imageSizeLimit: Number,
    videoSizeLimit: Number,
    stencilSize: Object,
    canvas: {
      type: Object,
      default() {
        return { minHeight: 0, minWidth: 0, maxHeight: 1600, maxWidth: 1600 };
      },
    },
  },
  emits: ["opened", "closed", "selected"],
  setup(props, { emit }) {
    const store = useStore();
    
    const $t = inject("$translation");

    const mediaSources = ref([]);

    onMounted(() => reset);

    const reset = () => {
      mediaSources.value = [];
    };

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
      setTimeout(reset, 300);
    };

    const selectMedia = (refs, type) => {
      if (type === "IMAGE" && refs?.fileInputImage) {
        refs.fileInputImage.value = "";
        refs.fileInputImage.click();
      }

      if (type === "VIDEO" && refs?.fileInputVideo) {
        refs.fileInputVideo.value = "";
        refs.fileInputVideo.click();
      }
    };

    const loadMedia = (event) => {
      let input = event.target;

      for (let file of input?.files) {
        let type = "";

        let reader = new FileReader();

        if (file?.type?.indexOf("video") > -1) {
          type = "VIDEO";
        } else if (file?.type?.indexOf("image") > -1) {
          type = "IMAGE";
        } else {
          throw new Error(`${$t.getTranslation("LBL_PLEASE_SELECT_A_VALID_MEDIA")}`);
        }

        if (type === "VIDEO" && file?.size > props.videoSizeLimit * 1000 * 1024) {
          throw new Error(`${$t.getTranslation("LBL_ERROR_VIDEO_FILE_SIZE_EXCEEDED")} ${props.videoSizeLimit} MB`);
        }

        reader.onload = (e) => {
          mediaSources.value.push({ file, type, source: e.target.result });
        };

        reader.readAsDataURL(file);
      }
    };

    const changeMedia = (refs) => {
      refs.fileInputImage.click();
    };

    // FOR IMAGE TYPE
    const flipImage = (refs) => {
      refs.cropper.flip(true, false);
    };

    const rotateImage = (refs) => {
      refs.cropper.rotate(-90);
    };

    const cropperChange = ({ coordinates, canvas }, media) => {
      media.coordinates = coordinates;
      media.canvas = canvas;
    };

    const removeMedia = (refs, index) => {
      mediaSources.value.splice(index, 1);
    };

    const useMedia = async () => {
      let counter = 0;
      for (let media of mediaSources.value) {
        if (media.type === "IMAGE") {
          let isPNG = media?.source?.indexOf("image/png") > -1 ? true : false;
          media.blob = await new Promise((resolve) => media?.canvas?.toBlob(resolve, isPNG ? "image/png" : "image/jpeg"));
          counter++;
        } else {
          counter++;
        }
      }

      if (counter == mediaSources.value.length) {
        f7.popup.close();
        emit("selected", mediaSources.value);
      }
    };

    return {
      props,
      openPopup,
      closePopup,
      mediaSources,
      selectMedia,
      loadMedia,
      cropperChange,
      changeMedia,
      rotateImage,
      flipImage,
      removeMedia,
      useMedia,
      store
    };
  },
});
</script>

<style>
.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: #000 !important;
}
</style>

<style scoped>
.mediaupload-popup.popup .navbar .title {
  margin-left: 15px;
}
/* .upload-media-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 20px 0px 0px;
  padding: 20px 30px 0px;
} */
.select-media-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 40px 0px 0px;
  padding: 20px 30px 0px;
}
.select-media-container .button {
  width: 100%;
  margin-bottom: 20px;
}
.select-media-container img {
  width: 180px;
}

.upload-media-container video {
  margin: 0px auto;
  width: 100%;
}
.upload-media-container h1,
.select-media-container h1 {
  font-size: 28px;
  margin: 0px 0px 40px;
  font-weight: 400;
  text-align: center;
}
.upload-media-container p,
.select-media-container p {
  margin: 20px 0px;
  padding: 10px 50px;
  font-size: 14px;
  text-align: center;
}
input[type="file"] {
  display: none;
}
.button-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.button-container div {
  display: flex;
  margin-top: 10px;
}
.button-container div:first-child {
  justify-content: flex-start;
}
.button-container div:last-child {
  justify-content: flex-end;
}
.button-container .button {
  min-width: 50px;
  margin-right: 10px;
}
.button-container .button:last-child {
  margin-right: 0px;
}
.button-container .button-fill {
  min-width: 160px;
}
.cropper {
  width: 460px;
  height: 380px;
}
.mediaupload-popup .swiper-wrapper {
  height: auto !important;
  align-items: center !important;
}
.button-fixed-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.button-fixed-container .button {
  min-width: 160px;
}
.vue-rectangle-stencil {
  z-index: 9999 !important;
}
@media only screen and (max-width: 899px) {
  .upload-media-container img {
    width: 180px;
    margin-top: 60px;
  }
  .upload-media-container h1 {
    font-size: 24px;
    margin: 60px 0px 0px;
  }
  .upload-media-container p {
    padding: 10px 0px;
  }
  .upload-media-container p strong {
    display: block;
    margin-top: 10px;
  }
  .upload-media-container .button-container {
    justify-content: center;
    flex-direction: column;
  }
  .upload-media-container .button-container div {
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>
